<template>
  <div class="addTesting">
    <BaseDialog :title="ruleForm.id ? '修改口服药' : '新增口服药'" :isshow="visible" @handleShow="
        (val) => {
          visible = val
        }
      " width="800px" top="10%">
      <div class="cont-main">
        <span>开始</span>
        <el-form :model="ruleForm" ref="ruleFormRef" :inline="true" label-width="120px" label-position="right"
          class="demo-ruleForm col-333">
          <el-form-item label="日期：" prop="start_date">
            <el-date-picker v-model="ruleForm.start_date" type="date" value-format="YYYY-MM-DD"
              placeholder="请选择"></el-date-picker>
          </el-form-item>
          <el-form-item label="时间：">
            <el-time-picker v-model="ruleForm.start_time" value-format="HH:mm" format="HH:mm" placeholder="时间">
            </el-time-picker>
          </el-form-item>
          <el-form-item label="医生签名：">
            <el-input clearable v-model="ruleForm.start_doctor"></el-input>
          </el-form-item>
          <el-form-item label="护士签名：">
            <el-input clearable v-model="ruleForm.start_nurse"></el-input>
          </el-form-item>
          <el-form-item label="医嘱：">
            <el-input v-model="ruleForm.advice_content" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea" placeholder="请输入医嘱">
            </el-input>
          </el-form-item>
        </el-form>
        <span>停止</span>
        <el-form :model="ruleForm" ref="ruleFormRef1" :inline="true" label-width="120px" label-position="right"
          class="demo-ruleForm col-333">
          <el-form-item label="日期：">
            <el-date-picker v-model="ruleForm.end_date" type="date" value-format="YYYY-MM-DD"
              placeholder="请选择"></el-date-picker>
          </el-form-item>
          <el-form-item label="时间：">
            <el-time-picker v-model="ruleForm.end_time" value-format="HH:mm" format="HH:mm" placeholder="时间">
            </el-time-picker>
          </el-form-item>
          <el-form-item label="医生签名：">
            <el-input  clearable v-model="ruleForm.end_doctor"></el-input>
          </el-form-item>
          <el-form-item label="护士签名：">
            <el-input  clearable v-model="ruleForm.end_nurse"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="color-cancel" type="primary" @click="visible = false">
            取消
          </el-button>
          <el-button @click="savePatient" :loading="saveLoad" class="color-determine" type="primary">
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
  import {
    reactive,
    ref,
    onMounted,
    defineExpose,
    defineEmits,
    nextTick,
  } from 'vue'
  import service from '@/utils/request'
  import BaseDialog from '@/components/Dialog/index.vue'
  import { ElMessage } from 'element-plus'


  const ruleForm = ref({})

  const visible = ref(false)

  const initData = (item,id) => {
    visible.value = true
    if (item) {
      ruleForm.value = item
    } else {
      ruleForm.value = {
        patient_id:id
      }
    }
  }

  // 保存
  const saveLoad = ref(false)
  const em = defineEmits(['refreshDataList'])
  const savePatient = async () => {
    console.log(ruleForm.value)
    if(ruleForm.value.end_date && ruleForm.value.start_date){
      if(Date.parse(ruleForm.value.start_date) > Date.parse(ruleForm.value.end_date)){
        ElMessage.error('结束时间必须大于或等于开始时间')
        return 
      }
    }
    // ruleFormRef.value.validate(async (valid) => {
    //   if (valid) {
    let url = ''
    if (ruleForm.value.id) {
      url = '/api/patient/edit_patient_advice_ext'
    } else {
      url = '/api/patient/create_patient_advice_ext'
    }
    saveLoad.value = true
    let res = await service.post(url, ruleForm.value)
    saveLoad.value = false
    if (res.code === 0) {
      ElMessage.success(res.msg)
      visible.value = false
      em('refreshDataList')
    }
    //   }
    // })
  }

  defineExpose({
    initData,
  })
</script>

<style scoped lang="scss">
  .cont-main {
    flex: 1;
    // max-height: 600px;
    // overflow-y: auto;
    // overflow-x: hidden;
    margin-top: 16px;

    .el-form {
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 20px;
    }

    .el-form-item {
      width: 47%;
      margin-right: 0.5%;

      &.addr {
        width: 100%;
        margin-right: 0;

        &.but {
          width: calc(100% - 62px);
        }
      }
    }
  }
</style>