<template>
  <div class="patentInfo" ref="tableBoxRef">
    <el-scrollbar>
      <div class="cont">
        <div class="cont-main col999">
          <el-collapse v-model="activeCollNames" accordion>
            <el-collapse-item name="1" disabled>
              <template #title>
                <div class="updateBox flex-between">
                  <div class="type">
                    <div class="tit" @click="handleChange">
                      <span>长期医嘱</span>
                      <i class="fa fa-angle-right" :class="!activeCollNames ? 'bottom' : ''"></i>
                    </div>
                  </div>
                  <div class="upButtom">
                    <el-button class="color-main" type="primary" @click="openWeight('addYao')">
                      <i class="fa fa-plus-circle"></i>新增
                    </el-button>
                    <el-button class="color-main" type="primary" @click="exportTable(1)">
                      导出长期医嘱
                    </el-button>
                    <el-button class="color-main" type="primary" @click="exportTable(2)">
                      导出口服药
                    </el-button>
                  </div>
                </div>
              </template>
              <div class="tables tableBorder" >
                <el-table class="sss" v-loading="dataListLoading" :data="tableData" size="small" border
                :max-height="tableHei" v-if="istable">
                  <el-table-column width="60" label="打印" fixed>
                    <template #default="scope">
                      <el-checkbox v-model="scope.row.is_print"></el-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column prop="advice_content" label="医嘱内容" fixed />
                  <el-table-column width="410" label="开始" align="center">
                    <el-table-column width="120" label="日期" prop="start_date">
                    </el-table-column>
                    <el-table-column width="90" label="时间" prop="start_time">
                    </el-table-column>
                    <el-table-column width="100" label="医生" prop="start_doctor">
                    </el-table-column>
                    <el-table-column width="100" label="护士" prop="start_nurse">
                    </el-table-column>
                  </el-table-column>
                  <el-table-column width="410" label="停止" align="center">
                    <el-table-column width="120" label="日期" prop="end_date">
                    </el-table-column>
                    <el-table-column width="90" label="时间" prop="end_time">
                    </el-table-column>
                    <el-table-column width="100" label="医生" prop="end_doctor">
                    </el-table-column>
                    <el-table-column width="100" label="护士" prop="end_nurse">
                    </el-table-column>
                  </el-table-column>

                  <el-table-column fixed="right" label="操作" width="90">
                    <template #default="scope">
                      <el-button type="text" size="small" @click="openWeight('viewYao',scope.row)">
                        <i class="txIons modify"></i>
                      </el-button>
                      <el-button type="text" size="small" @click="delParient(scope.row)">
                        <i class="txIons del"></i>
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </el-scrollbar>
    <addYao ref="addYaoRef" v-if="isAddYao" :patientId="patientId" @refreshDataList="getDataList"></addYao>
  </div>
</template>
<script>
  import { reactive, toRefs, onMounted, nextTick, watch } from 'vue'
  import { getConstHei, downLoadDocx } from '@/utils/tool.js'
  import service from '@/utils/request'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import BaseDialog from '@/components/Dialog/index.vue'
  import addYao from './addYao.vue'
  export default {
    name: 'programme',
    components: {
      BaseDialog,
      addYao
    },
    props: ['patientData', 'searchListData'],
    emits: ['addCallback'],
    setup(props, ctx) {
      const state = reactive({
        ruleForm: {},
        infoHei: '',
        searchData: {
        },
        tableData: [],
        pageData: {
          currentPage: 1,
          pageSize: 1000,
          totalSum: 0,
        },
        dataListLoading: false,
        stupData: {},
        visibleItem: false,
        saveLoading: false,
        patientId: 0,
        record_id: 0,
        activeCollNames: '1',
        isAddYao: false,
        addYaoRef: null,
        idList: [],
        istable: false,
        tableBoxRef: null,
        tableHei: 0,
      })

      watch(
        () => props.patientData.patient_id,
        (val) => {
          if (val) {
            state.patientId = val
            state.record_id = props.patientData.record_id
          } else {
            state.patientId = 0
            state.record_id = 0
          }
          getDataList()
        }
      )

      onMounted(() => {
        state.istable = false
        state.tableHei = state.tableBoxRef.clientHeight - 80 || 300
        console.log(state.tableHei)
        localStorage.setItem('tableHeight',state.tableHei)
        setTimeout(() => {
          state.istable = true
        }, 100);
        state.infoHei = getConstHei()
        console.log(props.patientData.schedule_date)
        if (props.patientData.patient_id) {
          state.patientId = props.patientData.patient_id
          state.record_id = props.patientData.record_id
        } else {
          state.patientId = 0
          state.record_id = 0
        }
        getDataList()
      })
      const getDataList = async () => {
        state.searchData.patient_id = state.patientId
        state.searchData.record_id = state.record_id
        state.dataListLoading = true
        let data = {
          page: 1,
          size: 1000,
          // date: props.patientData.schedule_date,
          patient_id: state.searchData.patient_id
        }
        let res = await service.post(
          '/api/patient/list_patient_advice_ext',
          data
        )
        state.dataListLoading = false
        if (res.code === 0) {
          res.data.list.forEach(item => {
            item.is_print = false
          })
          state.tableData = res.data.list
        }
      }

      const handleSizeChange = (val) => {
        state.pageData.pageSize = val
        getDataList()
      }

      const handleCurrentChange = (val) => {
        state.pageData.currentPage = val
        getDataList()
      }

      const openWeight = (type, val) => {
        if (!state.patientId || state.patientId === 0) {
          ElMessage({
            message: '请先选择患者',
            type: 'warning',
          })
          return
        }
        switch (type) {
          case 'addYao':
            state.isAddYao = true
            nextTick(() => {
              state.addYaoRef.initData('', state.patientId)
            })
            break
          case 'viewYao':
            state.isAddYao = true
            nextTick(() => {
              state.addYaoRef.initData(val, state.patientId)
            })
            break
        }
      }

      const handleShow = (val) => {
        state.visibleItem = val
      }

      const deatilsClick = (item) => {
        state.stupData.id = item.id
        state.stupData.group_name = ''
        state.visibleItem = true
      }

      const modParient = (item) => {
        state.isAddLongIerm = true
        nextTick(() => {
          state.addLongIermRef.initData(item, '0')
        })
      }
      const delParient = (item) => {
        ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res = await service.post('/api/patient/del_patient_advice_ext', {
              id: item.id,
            })
            if (res.code === 0) {
              ElMessage.success(res.msg)
              getDataList()
            }
          })
          .catch(() => { })
      }


      const handleChange = () => {
        if (state.activeCollNames) {
          state.activeCollNames = ''
        } else {
          state.activeCollNames = '1'
        }
      }
      // 导出
      const exportTable = (row) => {
        if (row == 1) {
          let data = {
            patient_id: state.patientId
          }
          downLoadDocx('/api/patient/export_patient_advice_ext', data, props.patientData.patient_name + '长期医嘱.docx')
        } else {
          let arr = []
          state.tableData.forEach(item => {
            if (item.is_print) {
              arr.push(item.id)
            }
          })
          if (arr.length > 0) {
            let data = {
              patient_id: state.patientId,
              id_list: arr
            }
            downLoadDocx('/api/patient/export_patient_advice_ext', data, props.patientData.patient_name + '口服药.docx')
          } else {
            ElMessage.error('未选择打印内容！')
          }

        }

      }
      return {
        ...toRefs(state),
        openWeight,
        getDataList,
        deatilsClick,
        modParient,
        delParient,
        handleShow,
        handleSizeChange,
        handleCurrentChange,
        handleChange,
        exportTable
      }
    },
  }
</script>

<style scoped lang="scss">
  .tableBorder {
    ::v-deep .el-table.el-table th {
      background-color: #eaf0f7 !important;
      border-color: #ddd;
    }
  }

  .patentInfo {
    margin-top: 8px;
    height: 100%;
  }
  .cont{
    height: 100%;
  }
  .podBot {
    padding-bottom: 30px;
  }

  .updateBox {
    height: 36px;
    width: 100%;
    align-items: center;

    .el-radio-group {
      margin-right: 30px;
    }

    .type {
      display: flex;
      align-items: center;

      .tit {
        display: inline-block;
        font-size: 18px;
        color: #000000;
        line-height: 32px;
        margin-right: 20px;
        cursor: pointer;

        .fa {
          font-size: 24px;
          margin-left: 10px;
          color: #999999;
          transition: transform 0.3s;

          &.bottom {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .cont-main {
    &:deep(.el-collapse-item__arrow) {
      width: 0;
    }

    &:deep(.el-collapse-item__header) {
      border-bottom: 0;
      cursor: default;
    }

    &:deep(.el-collapse-item__wrap) {
      border-bottom: 0;
    }
  }

  .tables {
    margin-top: 20px;
  }

  .parient-tag {
    .el-tag {
      margin-right: 10px;
    }
  }

  .tongluBox {
    margin-top: 20px;
  }
</style>